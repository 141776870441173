
import Vue from "vue";
import Component from "vue-class-component";

@Component
export default class Offers extends Vue {
  get customClassName (): string {
    return `${ this.$route.name?.toLowerCase().replace( / /ig, '-' ) }-view`
  }
}
