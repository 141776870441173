
import { Component, Vue } from "vue-property-decorator";
import store from "../../store/index";
import {
  cancelPayoutRequest,
  fetchLastPendingPayout,
  PayoutType,
} from "@/transactions/payoutWebServices";
import BankTransferPayoutForm, { BankTransferForm, onBankTransferPayout } from "./BankTransferPayoutForm.vue";
import PaypalPayoutForm, { onPaypalPayout, PaypalForm } from "./PaypalPayoutForm.vue";
import { pushError } from "@/core";
import { currencies } from "@/transactions/currencies";
import { TopUpTransferType } from "../top-up/topUpTypes";
import { PayoutForm } from "./PayoutFormBase";

@Component({
  components: {
    BankTransferPayoutForm,
    PaypalPayoutForm,
  },
})
export default class Payout extends Vue {
  isLoading = false;
  form: Partial<PayoutForm> = { currency: currencies[0] };

  mounted() {
    this.isLoading = true;
    fetchLastPendingPayout()
      .then((response) => {
        if (!!response.data) {
          this.form = {
            amount: response.data.amount,
            type: response.data.type,
            payoutId: response.data.payoutId,
            details: response.data.details,
            currency: currencies.find(c => c.code == response.data.currency)
          };
        }
      })
      .finally(() => {
        this.isLoading = false;
      })
      .catch((error) => {
        pushError(error.response?.data?.error || error);
      });
  }  
  
  payoutTypeOptions = [
    { text: "PayPal", value: TopUpTransferType.PayPal },
    { text: this.$t("Bank transfer"), value: TopUpTransferType.BankTransfer },
  ]

  public async onSavePayout() {
    this.isLoading = true

    try {
      if (this.isPaypalType) {
        this.form = await onPaypalPayout(this.form as PaypalForm, this.feeInCoins)
      }

      if (this.isBankAccountType){
        this.form = await onBankTransferPayout(this.form as BankTransferForm, this.feeInCoins)
      }
      
    } catch (error) {
      pushError((error as any).response?.data?.error || error);
    } finally {
      this.isLoading = false
    }
  }

  public async onCancelPayout() {
    this.isLoading = true;
    cancelPayoutRequest(this.form.payoutId!)
      .then((response) => {
        store.commit.module.cancelTransaction({
          id: response.data.transactionId,
          comment: response.data.comment,
        });

        this.form.payoutId = undefined
      })
      .finally(() => {
        this.isLoading = false;
      })
      .catch((error) => {
          pushError(error.response?.data?.error || error);
      });
  }

  get isPaypalType() {
    return this.form.type === PayoutType.PayPal;
  }

  get isBankAccountType() {
    return this.form.type === PayoutType.BankTransfer;
  }

  get balance() {
    return store.getters.module.balance ?? 0;
  }

  get moreCoinsThanBalance() {
    return !!this.form.amount && this.form.amount > this.balance;
  }

  get amountInputState(): boolean | null {
    if (!!this.form.payoutId) {
      return null;
    }

    return (
      !this.moreCoinsThanBalance && !!this.form.amount && this.form.amount > 0
    );
  }

  get feeInCoins() {
    const additionalFeeUntil = 250;
    const additionalFee = 10;
    if(!this.form.amount) {
      return 0;
    }
    return this.form.amount > additionalFeeUntil ? 0 : additionalFee; 
  }

  get amountToPayout() {
    return this.form.currency!.ratePerUsd * (this.form.amount! - this.feeInCoins)
  }

  get isValid() {
    return this.amountInputState && !!this.form.currency && !!this.form.type && this.amountToPayout > 0;
  }

  get currencies() {
    return currencies
    .filter(c => c.allowInPayout)
    .map(c => { 
      return {
        text: `${c.code} (${c.symbol})`,
        value: c,
      }
    });
  }

  get canConfirm() {
    return this.form.injectedFormIsValid && this.isValid
  }

  get inputsDisabled(): boolean {
    return this.isLoading || !!this.form.payoutId
  }
}
