
import { Component, Vue } from "vue-property-decorator";
import store from "@/store";
import UserPanelRoot from "@/panel/UserPanelRoot.vue";

@Component({
  components: {
    UserPanelRoot,
  },
})
export default class Panel extends Vue {
  mounted() {
    store.commit.module.setActiveModuleName(this.$t("Panel"));
  }
}
