
import { Component, Prop, Vue } from "vue-property-decorator";
import RegisterModel, { AccountType } from "./registerModel";
import callRegister from "./callRegister";
import SelectAccountType from "./SelectAccountType.vue";
import LoginWithFacebook from "../login/LoginWithFacebook.vue";
import AcceptTerms from "./AcceptTerms.vue";
import { getLanguage } from "@/core/translate";
import { saveToStorage } from "../helpers/storageHelper";
import { pushError } from "@/core";
import { click } from "../serviceClients/invitationWebServices";

@Component({
  components: {
    SelectAccountType,
    LoginWithFacebook,
    AcceptTerms,
  },
})
export default class RegisterForm extends Vue {
  form: Partial<RegisterModel> = {
    accountType: undefined,
  };
  isLoading = false;
  termsAccepted = false;
  successfullyRegistered = false;

  get arePasswordsTyped(): boolean {
    return !!this.form.password && !!this.form.confirmPassword
  }

  get arePasswordsDifferent(): boolean {
    return this.form.password !== this.form.confirmPassword
  }

  get accountTypeSelected(): boolean {
    return !!this.form.accountType;
  }

  mounted() {
    this.form.language = getLanguage();
    saveToStorage("invitationId", this.$route.params.invitationId);
    if(this.$route.params.invitationId) {
      click(this.$route.params.invitationId)
            .catch(() => { /* no need for error handling or loading indicator - it shall be transparent to users */ });
    }
    this.form.referralUserId = this.$route.params.invitationId;
    if (this.$route.params.invitationId) {
      this.form.accountType = AccountType.Client;
    }
  }

  onSubmit() {
    this.isLoading = true;

    callRegister(this.form as RegisterModel)
      .then(() => {
        this.successfullyRegistered = true;
      })
      .finally(() => {
        this.isLoading = false;
      })
      .catch((error) => {
        const errorMessage = error.response?.data?.error || error;
        const opts = { title: this.$t("An error occurred while registering") };
        if (errorMessage === "DuplicatedEmail") {
          pushError(this.$t("Provided email is already in use"), opts);
        } else {
          pushError(errorMessage, opts);
        }
      });
  }
}
