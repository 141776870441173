
import { Component, Prop, Vue } from "vue-property-decorator";
import {
  addCategoryCertificate,
  deleteCategoryCertificate,
  UserCategoryCertificate,
  UserCategoryModel,
} from "./verifySkillsWebServices";
import VueUploadComponent from "vue-upload-component";
import { pushError } from "../core";

@Component({
  components: {
    VueUploadComponent,
  },
})
export default class SkillItem extends Vue {
  @Prop() item!: UserCategoryModel;

  certsToShow: UserCategoryCertificate[] = [];
  isSaving = false;
  form: {
    description: string;
    file: VUFile[];
  } = {
    description: "",
    file: [],
  };

  mounted() {
    this.certsToShow = [...this.item.userCategoryCertificates];
  }

  get descriptionFilled(): boolean {
    return !!this.form.description && this.form.description.length > 5;
  }

  get verifiedText() {
    return this.item.verified
      ? this.$t("Verified. You have earned the badge: {0}", [
          this.item.category.verifiedBadge,
        ])
      : this.$t("Unverified");
  }

  get certificatesExist() {
    return !!this.certsToShow.length;
  }

  get canSave() {
    return this.descriptionFilled && !!this.form.file.length;
  }

  removeFileToUpload() {
    this.form.file = [];
  }

  removeFile(id: string) {
    this.isSaving = true;
    deleteCategoryCertificate(id)
      .then(() => {
        this.certsToShow = this.certsToShow.filter((x) => x.id !== id);
      })
      .finally(() => {
        this.isSaving = false;
      })
      .catch((error) => {
          pushError(error.response?.data?.error || error);
      });
  }

  resetForm() {
    this.form = {
      description: "",
      file: [],
    };
  }

  onSave() {
    this.isSaving = true;
    addCategoryCertificate({
      categoryId: this.item.category.id,
      description: this.form.description,
      file: this.form.file[0].file,
    })
      .then((response) => {
        this.resetForm();
        this.certsToShow.push({
          ...response.data,
        });
      })
      .finally(() => {
        this.isSaving = false;
      })
      .catch((error) => {
          pushError(error.response?.data?.error || error);
      });
  }
}
