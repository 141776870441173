
import { Currency } from "@/serviceClients/userWebServices";
import { Component, Prop, Vue } from "vue-property-decorator";
import { currencies } from "../currencies";
import { TopUpPackage } from "./topUpTypes";
import { displayPrice, packageOldPrice, packagePrice } from "./topUpHelpers";
import topUpPackages from "./topUpPackages";

@Component
export default class TopUpPackagesMobile extends Vue {
  @Prop() onSelectPackage!: (pack: TopUpPackage, currency: Currency) => Promise<void>
  @Prop() selectedPackage: TopUpPackage | null = null

  currencies: Currency[] = currencies
  packages: TopUpPackage[] = topUpPackages

  selectedCurrency: Currency = this.currencies[0]

  packagePrice(pack: TopUpPackage, currency: Currency): number {
    return packagePrice(pack, currency)
  }

  packageOldPrice(pack: TopUpPackage, currency: Currency): number | undefined {
    return packageOldPrice(pack, currency)
  }

  async onSelectCurrency(value: Currency) {
    if (this.selectedPackage){
      await this.onSelectPackage(this.selectedPackage, value)
    }
  }  
  
  displayPrice(price: number, currency: Currency): string {
    return displayPrice(price, currency)
  }

  get currenciesOptions() {
    return this.currencies.map((c) => {
      return {
        text: `${c.code} (${c.symbol})`,
        value: c,
      }
    })
  }
}
