
import confirmRegistration from "@/confirmRegistration/callConfirmRegistration";
import { Component, Vue } from "vue-property-decorator";
import store from "@/store";
import { pushError } from "@/core";

@Component
export default class ConfirmRegistration extends Vue {
  isLoading = true;

  mounted() {
    store.commit.module.setActiveModuleName(this.$t("Confirm registration"));

    confirmRegistration(this.$route.params.id)
      .then(() => {
        this.$toasted.success(
          this.$t("Registration confirmed successfully. Please log in now.").toString(),
          { duration: 5000 }
        );
        this.$router.push({ path: "/login" })
            .catch(() => { /* noop */ });
      })
      .finally(() => (this.isLoading = false))
      .catch((error) => {
        const errorMessage = error.response?.data?.error || error;
        if (errorMessage === "UserNotFound") {
          pushError(this.$t("Email address has not been found. Please register first."));
          this.$router.push({ path: "/register" })
            .catch(() => { /* noop */ });
        }
        else {
          pushError(errorMessage);
        }
      });
  }
}
