
import { Component, Prop, Vue } from "vue-property-decorator";
import { AccountType } from "./registerModel";

@Component
export default class SelectAccountType extends Vue {
  @Prop() value!: AccountType;

  get localState() {
    return this.value;
  }

  set localState(value: any) {
    this.$emit("input", value);
  }

  isLoading = false;
  error: string = "";

  get assistantBgVariant() {
    return this.localState === AccountType.Assistant ? "primary" : "";
  }

  get assistantTextVariant() {
    return this.localState === AccountType.Assistant ? "white" : "";
  }

  get clientBgVariant() {
    return this.localState === AccountType.Client ? "primary" : "";
  }

  get clientTextVariant() {
    return this.localState === AccountType.Client ? "white" : "";
  }

  onAssistantClicked() {
    this.localState = AccountType.Assistant;
  }

  onClientClicked() {
    this.localState = AccountType.Client;
  }

  mounted() {
  }
}
