
import Vue from "vue";
import LoginForm from "@/login/LoginForm.vue";
import Component from "vue-class-component";
import store from "@/store";
import { Carousel, Slide } from "vue-carousel";


@Component({
  components: {
    LoginForm,
    Carousel,
    Slide
  },
})
export default class Login extends Vue {
  mounted() {
    store.commit.module.setActiveModuleName(this.$t("Login"));
  }
}
