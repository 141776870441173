

import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class SelectAccountType extends Vue {
  @Prop() value!: boolean;

  

  get localState() {
    return this.value;
  }

  set localState(value: any) {
    this.$emit("input", value);
  }
  mounted() {}
}
