import { vue } from "@/main";
import { Currency } from "@/serviceClients/userWebServices";

export const bankTransferMessagesHtml = (currency: Currency) => {
    if (currency!.code == "PLN") {
        return (
            `<b>${vue.$t("Company")}:</b><br/>` +
            `Assistantline Inc.<br/>` +
            `<b>${vue.$t("Account number")}:</b><br/>` +
            `70 1020 1026 3741 0000 0005 2087`
        );
    } else if (currency!.code == "EUR") {
        return (
            `<b>${vue.$t("Company")}:</b><br/>` +
            `Assistantline Inc.<br/>` +
            `<b>BIC:</b><br/>` +
            `TRWIBEB1XXX<br/>` +
            `<b>IBAN:</b><br/>` +
            `BE65 9671 9547 7796`
        );
    } else if (currency!.code == "USD") {
        return (
            `<h3>For US transfers:</h3>` +
            `<b>${vue.$t("Company")}:</b><br/>` +
            `Assistantline Inc.<br/>` +
            `<b>Routing number:</b><br/>` +
            `084009519<br/>` +
            `<b>${vue.$t("Account number")}:</b><br/>` +
            `9600000000619300<br/>` +
            `${vue.$t("Account type")}:<br/>` +
            `Checking<br/>` +
            `<br/>` +
            `<h3>For foreign transfers:</h3>` +
            `<b>${vue.$t("Company")}:</b><br/>` +
            `Assistantline Inc.<br/>` +
            `<b>SWIFT/BIC:</b><br/>` +
            `CMFGUS33<br/>` +
            `<b>${vue.$t("Account number")}:</b><br/>` +
            `822000254869`
        );
    }
}