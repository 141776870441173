
import { Component, Vue } from "vue-property-decorator";
import TransactionList from "@/transactions/TransactionList.vue";
import TransactionListMobile from "@/transactions/TransactionListMobile.vue";
import TopUp from "@/transactions/top-up/TopUp.vue";
import Payout from "@/transactions/payout/Payout.vue";
import store from "../store";
import { AccountType } from "../register/registerModel";

@Component({
  components: {
    TransactionList,
    TransactionListMobile,
    TopUp,
    Payout,
  },
})
export default class Transactions extends Vue {
  mounted() {
    store.commit.module.setActiveModuleName(this.$t("Balance"));
  }

  get isClient() {
    return store.getters.module.user!.accountType === AccountType.Client;
  }
}
