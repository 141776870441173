
export interface TopUpPackage {
    coins: number;
    priceInCurrency: TopUpPackagePriceInCurrency[];
}

export interface TopUpPackagePriceInCurrency {
    currencyCode: string;
    price: number;
    oldPrice?: number;
}

export enum TopUpTransferType {
    PayPal = 1,
    BankTransfer = 2,
}