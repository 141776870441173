
import {
  TransactionStatus,
  TransactionType,
} from "@/serviceClients/userWebServices";
import store from "@/store";
import { Component, Prop } from "vue-property-decorator";
import { createBankTransferPayout } from "../payoutWebServices";
import PayoutFormBase, { PayoutForm } from "./PayoutFormBase";
import { currencies } from "@/transactions/currencies";

export type BankTransferForm = {
  name: string;
  address: string;
  iban: string;
  swift: string;
} & PayoutForm;

export const onBankTransferPayout = async (value: BankTransferForm, feeInCoins: number) => {
  const response = await createBankTransferPayout({
      feeInCoins: feeInCoins,
      ...value,
    })

    store.commit.module.addTransaction({
      id: response.data.transactionId,
      amount: response.data.amount,
      comment: response.data.comment,
      transactionDateTime: new Date().toString(),
      status: TransactionStatus.Pending,
      type: TransactionType.PayOut,
    })

    return {
      amount: response.data.amount,
      type: response.data.type,
      payoutId: response.data.payoutId,
      details: response.data.details,
      currency: currencies.find(c => c.code == response.data.currency)
    }
}

@Component
export default class BankTransferPayoutForm extends PayoutFormBase {
  @Prop() value!: BankTransferForm;

  mounted() {
    this.updateStateValidity()
  }

  updateStateValidity() {
    this.$emit('input', {
      ...this.value,
      injectedFormIsValid: this.totalStateIsOk(),
    })
  }

  totalStateIsOk() {
    return !!this.value.name && !!this.value.address && !!this.value.iban
  }
}
