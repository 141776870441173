import { Currency } from "@/serviceClients/userWebServices";
import { Prop, Vue } from "vue-property-decorator";
import { PayoutType } from "../payoutWebServices";

export type PayoutForm = {
    amount: number;
    type: PayoutType;
    payoutId?: string;
    details?: string;
    currency: Currency;
    injectedFormIsValid: boolean;
};

export default abstract class PayoutFormBase extends Vue {
    public abstract value: PayoutForm;
    protected abstract totalStateIsOk(): boolean;
    protected abstract updateStateValidity(): void;

    @Prop() disabled: boolean = false;
}