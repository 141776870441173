import { Currency } from "@/serviceClients/userWebServices"
import { TopUpPackage } from "./topUpTypes"

export const packagePrice = (pack: TopUpPackage, currency: Currency) => {
    return pack.priceInCurrency.find(x => x.currencyCode === currency.code)!.price
}

export const packageOldPrice = (pack: TopUpPackage, currency: Currency) => {
    return pack.priceInCurrency.find(x => x.currencyCode === currency.code)!.oldPrice
}

export const displayPrice = (price: number, currency: Currency): string => {
    return `${price.toFixed(2)} ${currency.code}`
}