
import { Component, Vue } from "vue-property-decorator";
import store from "@/store";
import { pushError } from "@/core";
import * as passwordClient from "@/accountPassword/accountPasswordClient"
import { Carousel, Slide } from "vue-carousel";

@Component({
  components: {
    Carousel,
    Slide
  }
})
export default class ForgotPassword extends Vue {
  isLoading = false
  email: string = ''

  mounted() {
    store.commit.module.setActiveModuleName(this.$t("Reset password"));
  }

  onSubmit() {
    this.isLoading = true;

    passwordClient.requestResetPassword(this.email)
      .then(() => {
        this.$toasted.success(
          this.$t("Reset password instruction has been sent. Check your e-mail inbox.").toString(),
          { duration: 5000 }
        );
      })
      .finally(() => { this.isLoading = false })
      .catch((error) => {
        const errorMessage = error.response?.data?.error || error;
        if (errorMessage === "UserNotFound") {
          pushError(this.$t("Email address has not been found."));
        }
        else {
          pushError(errorMessage);
        }
      });
  }
}
