
import { Component, Vue } from "vue-property-decorator";
import store from "../../store/index";
import {
  createPaypalTransaction,
  Transaction,
  setPaypalTransactionSuccess,
  setPaypalTransactionError,
  TransactionStatus,
  TransactionType,
  createOfflineTransaction,
  Currency,
  TopUpTransactionType,
} from "../../serviceClients/userWebServices";
import { getPaypalClientId, getVatPercentage } from "../../helpers/settingsHelper";
import { pushError } from "@/core";
import { currencies } from "@/transactions/currencies";
import { TopUpPackage, TopUpTransferType } from './topUpTypes'
import TopUpPackagesMobile from './TopUpPackagesMobile.vue'
import { packagePrice, packageOldPrice, displayPrice } from './topUpHelpers'
import topUpPackages from './topUpPackages'
import { bankTransferMessagesHtml } from './bankTransferMessages'
import { fetchInvoiceDetails } from "@/panel/userPanelWebServices";

declare const paypal: any;

@Component({
  components: {
    TopUpPackagesMobile
  }
})
export default class TopUp extends Vue {
  isLoading = false;
  transaction: Transaction | null = null;
  selectedPackage: TopUpPackage | null = null;
  selectedCurrency: Currency | null = null;
  topUpTransactionType: TopUpTransactionType = TopUpTransactionType.Business;
  topUpTransferType: TopUpTransferType | null = null;
  userWantsToGetInvoice = false;
  isPaypalButtonsLoading = false;
  invoiceDetails = '';

  currencies: Currency[] = currencies;

  get coins() {
    return this.selectedPackage?.coins;
  }

  topUpTransactionTypeOptions = [
    { text: this.$t("Business services"), value: TopUpTransactionType.Business },
    { text: this.$t("Entertainment"), value: TopUpTransactionType.Entertainment },
  ]

  topUpTransferTypeOptions = [
    { text: "PayPal", value: TopUpTransferType.PayPal },
    { text: this.$t("Bank transfer"), value: TopUpTransferType.BankTransfer },
  ]

  get isPayPalTransferTypeSelected() {
    return this.topUpTransferType === TopUpTransferType.PayPal
  }

  get isBankTransferTypeSelected() {
    return this.topUpTransferType === TopUpTransferType.BankTransfer
  }

  get currencyFlag() {
    switch (this.selectedCurrency!.code) {
      case 'EUR':
        return 'ue';
      case 'PLN':
        return 'pl';
      default:
        return 'usa';
    }
  }

  get totalPriceToPayText() {
    const currencyText = this.selectedCurrency?.code ?? ''
    const priceText = currencyText && this.selectedPackage && this.selectedCurrency ? packagePrice(this.selectedPackage, this.selectedCurrency) : 0

    return `${priceText} ${currencyText}`
  }

  packages: TopUpPackage[] = topUpPackages

  packagePrice(pack: TopUpPackage, currency: Currency): number {
    return packagePrice(pack, currency)
  }

  packageOldPrice(pack: TopUpPackage, currency: Currency): number | undefined {
    return packageOldPrice(pack, currency)
  }

  displayPrice(price: number, currency: Currency): string {
    return displayPrice(price, currency)
  }

  get chargedAmount() {
    if (!this.selectedPackage) {
      return 0;
    }
    const amountAfterCommision = packagePrice(this.selectedPackage!, this.selectedCurrency!);
    const amountAfterTax = (amountAfterCommision * (100 + this.vatPercentage)) / 100;
    return amountAfterTax;
  }

  get vatPercentage() {
    return getVatPercentage();
  }

  async mounted() {
    if ((this as any).$isMobile()) {
      this.selectedCurrency = this.currencies.filter((c) => c.code === "USD")[0];
    }

    const invoiceDetailsResponse = await fetchInvoiceDetails(store.getters.module.user!.id)
    this.invoiceDetails = invoiceDetailsResponse.data
  }

  get bankTransferHtml() {
    return bankTransferMessagesHtml(this.selectedCurrency!)
  }

  async onSelectPackage(pack: TopUpPackage, currency: Currency) {
    var switchingCurrency = false;
    if (this.selectedCurrency !== currency) {
      switchingCurrency = true;
    }

    this.selectedPackage = pack;
    this.selectedCurrency = currency;

    if (this.topUpTransferType === TopUpTransferType.PayPal && switchingCurrency) {
      await this.showPayPalButtons();
    }
  }

  async onTopUpTransferTypeChange(topUpTransferType: TopUpTransferType) {
    if (this.selectedCurrency && this.selectedPackage && topUpTransferType === TopUpTransferType.PayPal) {
      await this.showPayPalButtons()
    }
  }

  onBankTransfer() {
    this.isLoading = true;
    createOfflineTransaction(store.getters.module.user!.id, {
      coins: this.selectedPackage!.coins,
      price: packagePrice(this.selectedPackage!, this.selectedCurrency!),
      currency: this.selectedCurrency!,
      topUpTransactionType: this.topUpTransactionType,
      bankTransferHtml: this.bankTransferHtml,
    })
      .then((response) => {
        store.commit.module.addTransaction(response.data);
        this.$toasted.success(
          this.$t(
            "Bank transfer top up has been ordered. Check your e-mail inbox for further details."
          ).toString(),
          { duration: 5000 }
        );
      })
      .finally(() => {
        this.isLoading = false;
      })
      .catch((error) => {
        pushError(error.response?.data?.error || error);
      });
  }

  async showPayPalButtons() {
    this.isPaypalButtonsLoading = true;
    await (Vue as any).loadScript(`https://www.paypal.com/sdk/js?client-id=${getPaypalClientId()}&currency=${this.selectedCurrency!.code}`);
    (this.$refs["paypalButtons"] as Element)!.innerHTML = "";
    paypal
      .Buttons({
        createOrder: (data: any, actions: any) => {
          this.transaction = {
            amount: this.selectedPackage!.coins,
            type: TransactionType.OnlineTopUp,
            status: TransactionStatus.Pending,
            transactionDateTime: new Date().toString(),
          } as Transaction;

          // This function sets up the details of the transaction, including the amount and line item details.
          createPaypalTransaction(store.getters.module.user!.id, {
            coins: this.selectedPackage!.coins,
            price: packagePrice(this.selectedPackage!, this.selectedCurrency!),
            currency: this.selectedCurrency!,
            topUpTransactionType: this.topUpTransactionType,
          })
            .then((result) => {
              this.transaction = result.data;
            })
            .catch((error) => {
              pushError(error.response?.data?.error || error);
            });

          return actions.order.create({
            purchase_units: [
              {
                amount: {
                  value: this.chargedAmount.toFixed(2),
                  currency_code: this.selectedCurrency!.code,
                },
              },
            ],
          });
        },
        onApprove: (data: any, actions: any) => {
          // This function captures the funds from the transaction.
          return actions.order.capture().then(() => {
            this.onPaypalApprove();
          });
        },
        onError: (err: any) => {
          pushError(err);
          this.onError(err);
        },
      })
      .render("#paypal-button-container");
      this.isPaypalButtonsLoading = false;
  }

  onPaypalApprove() {
    this.isLoading = true;
    this.transaction!.status = TransactionStatus.Successful;
    store.commit.module.addTransaction(this.transaction!);
    setPaypalTransactionSuccess(store.getters.module.user!.id, this.transaction!.id)
      .then(() => {
        this.transaction = null;
        this.$toasted.success(this.$t("Payment successful").toString(), {
          duration: 5000,
        });
      })
      .finally(() => {
        this.isLoading = false;
        store.dispatch.module.loadBalance().catch(() => {
          /* noop */
        });
      })
      .catch((error) => {
        pushError(error.response?.data?.error || error);
      });
  }

  onError(error: any) {
    this.transaction!.status = TransactionStatus.Error;
    store.commit.module.addTransaction(this.transaction!);
    setPaypalTransactionError(
      store.getters.module.user!.id,
      this.transaction!.id,
      error.toString()
    ).catch((error) => {
      pushError(error.response?.data?.error || error);
    });
  }
}
