
import RegisterForm from "@/register/RegisterForm.vue";
import store from "@/store";
import { Component, Prop, Vue } from "vue-property-decorator";
import { Carousel, Slide } from "vue-carousel";

@Component({
  components: {
    RegisterForm,
    Carousel,
    Slide
  }
})
export default class Register extends Vue {
  mounted() {
    store.commit.module.setActiveModuleName(this.$t("Register"));
  }
};
