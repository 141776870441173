
import { Component, Prop, Vue } from "vue-property-decorator";
import store from "../store/index";
import SelectAccountType from "../register/SelectAccountType.vue";
import AcceptTerms from "../register/AcceptTerms.vue";
import { AccountType } from "../register/registerModel";
import { setAccountType } from "../serviceClients/userWebServices";
import { pushError } from "@/core";


@Component({
  components: {
    SelectAccountType,
    AcceptTerms,
  },
})
export default class SelectType extends Vue {
  isLoading = false;
  accountType: AccountType = AccountType.Unknown;
  termsAccepted = false;

  mounted() {
    store.commit.module.setActiveModuleName(this.$t("Choose account type"));

    if (store.getters.module.user!.accountType !== AccountType.Unknown) {
      this.$router.push("/")
        .catch(() => { /* noop */ });
    }
  }

  get isAccountTypeSelected() {
    return this.accountType !== AccountType.Unknown;
  }

  confirmClicked() {
    if (this.isAccountTypeSelected) {
      this.isLoading = true;
      setAccountType(store.getters.module.user!.id, this.accountType)
        .then(() => {
          const user = store.getters.module.user!;
          user.accountType = this.accountType;
          store.commit.module.setUser(user);
          store.dispatch.module.saveUserToStorage()
            .catch(() => { /* noop */ });
          this.$router.push("/")
            .catch(() => { /* noop */ });
        })
        .finally(() => {
          this.isLoading = false;
        })
        .catch((error) => {
          const errorMessage = error.response?.data?.error || error;
          if (errorMessage === "InvalidCredentials") {
            this.$t("Provided email or password are invalid");
          }
          else {
            pushError(errorMessage);
          }
        });
    }
  }
}
