
import { Component, Vue } from "vue-property-decorator";
import { AxiosResponse } from "axios";
import store from "../store/index";
import {
  Transaction,
  TransactionStatus,
  fetchTransactions,
} from "../serviceClients/userWebServices";

import { getBaseInvoicesUrl } from "@/helpers/settingsHelper";
import { pushError } from "@/core";
import { getTransactionFixedName } from "./transactionHelper";
import { transactionStatusTranslate } from "./transactionStatusHelper";

@Component
export default class TransactionList extends Vue {
  isLoading = false;

  get transactions() {
    return store.getters.module.transactions;
  }

  get fields() {
    return [
      {
        key: "name",
        label: this.$t("Transaction"),
        sortable: true
      },
      {
        key: "transactionDateTime",
        label: this.$t("Date"),
        sortable: true,
        formatter: (value: string) => {
          const date = new Date(value);
          return `${date.toLocaleString()}`;
        },
      },
      {
        key: "amount",
        label: this.$t("Amount"),
        sortable: true,
      },
      {
        key: "status",
        label: this.$t("Status"),
        sortable: true,
        formatter: (value: TransactionStatus) => {
          return transactionStatusTranslate(this, value);
        },
      },
      {
        key: "comment",
        label: this.$t("Comment"),
      },
      {
        key: "invoiceGenerationId",
        label: this.$t("Download invoice"),
      },
    ];
  }

  mounted() {
    this.isLoading = true;
    fetchTransactions(store.getters.module.user!.id)
      .then((response: AxiosResponse<Transaction[]>) => {
        store.commit.module.setTransactions(response.data);
      })
      .finally(() => {
        this.isLoading = false;
      })
      .catch((error) => {
        pushError(error.response?.data?.error || error);
      });
  }

  getInvoiceUrl(fileName: string) {
    return `${getBaseInvoicesUrl()}${fileName}`;
  }

  getName(item: Transaction) {
    return getTransactionFixedName(this, item);
  }
}
