import * as axios from 'axios';

const url = `${process.env.VUE_APP_API_URL}/verify-skills`;

type AddSkillCertificateModel = {
    categoryId: string;
    description: string;
    file: File;
};

type CategoryModel = {
    id: string;
    name: string;
    verifiedBadge: string;
}

export type UserCategoryModel = {
    category: CategoryModel;
    verified: boolean;
    userCategoryCertificates: UserCategoryCertificate[];
}

export type UserCategoryCertificate = {
    id: string;
    fileName: string;
    description: string;
};

export const getAllSkillCertificates = () => {
    return axios.default.get<UserCategoryModel[]>(url);
}

export const addCategoryCertificate = (data: AddSkillCertificateModel) => {
    const formData = new FormData();
    formData.append('CategoryId', data.categoryId)
    formData.append('File', data.file, data.file.name);
    formData.append('Description', data.description);

    return axios.default.post<UserCategoryCertificate>(`${url}/certificate`, formData, {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
        },
    })
}

export const deleteCategoryCertificate = (id: string) => {
    return axios.default.delete<void>(`${url}/certificate/${id}`);
}