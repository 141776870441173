
import { Component, Prop, Vue } from "vue-property-decorator";
import { pushError } from "../core";
import SkillItem from "./SkillItem.vue";
import {
  getAllSkillCertificates,
  UserCategoryModel,
} from "./verifySkillsWebServices";

@Component({
  components: {
    SkillItem,
  },
})
export default class VerifySkillsMain extends Vue {
  isLoading = true;
  skillCertificates: UserCategoryModel[] = [];

  mounted() {
    getAllSkillCertificates()
      .then((response) => {
        this.skillCertificates = response.data;
      })
      .finally(() => {
        this.isLoading = false;
      })
      .catch((error) => {
          pushError(error.response?.data?.error || error);
      });
  }
}
