
import { Component, Vue } from "vue-property-decorator";
import store from "@/store";
import { pushError } from "@/core";
import * as passwordClient from "@/accountPassword/accountPasswordClient"

@Component
export default class ForgotPassword extends Vue {
  isLoading = false
  newPassword: string = ''

  mounted() {
    store.commit.module.setActiveModuleName(this.$t("Reset password"));
  }

  onSubmit() {
    this.isLoading = true;

    passwordClient.resetPassword(
      {
        resetPasswordRequestId: this.$route.params.requestPasswordResetId,
        userId: this.$route.params.userId,
        newPassword: this.newPassword,
      })
      .then(() => {
        this.$toasted.success(
          this.$t("Password has been reset successfully. You can login now!").toString(),
          { duration: 5000 }
        );
        this.$router.push("/login").catch(() => { /* noop */ });
      })
      .finally(() => { this.isLoading = false })
      .catch((error) => {
        const errorMessage = error.response?.data?.error || error;
        if (errorMessage === "ResetNotFound") {
          pushError(this.$t("Your link has expired. Please try again."));
        }
        else if (errorMessage === "PasswordWasUsed") {
          pushError(this.$t("Password has already been used. Try different password."));
        }
        else {
          pushError(errorMessage);
        }
      });
  }
}
