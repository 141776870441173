
import { Component, Vue } from "vue-property-decorator";
import { AxiosResponse } from "axios";
import store from "../store/index";
import {
  Transaction,
  fetchTransactions,
  TransactionStatus,
} from "../serviceClients/userWebServices";
import { getBaseInvoicesUrl } from "@/helpers/settingsHelper";
import { pushError } from "@/core";
import { transactionStatusTranslate } from "./transactionStatusHelper"
import { getTransactionFixedName } from "./transactionHelper"

@Component
export default class TransactionListMobile extends Vue {
  isLoading = false;

  get transactions() {
    return store.getters.module.transactions;
  }

  mounted() {
    this.isLoading = true;
    fetchTransactions(store.getters.module.user!.id)
      .then((response: AxiosResponse<Transaction[]>) => {
        store.commit.module.setTransactions(response.data);
      })
      .finally(() => {
        this.isLoading = false;
      })
      .catch((error) => {
        pushError(error.response?.data?.error || error);
      });
  }

  getStatus(status: TransactionStatus) {
    return transactionStatusTranslate(this, status)
  }

  getInvoiceUrl(fileName: string) {
    return `${getBaseInvoicesUrl()}${fileName}`;
  }

  getName(item: Transaction) {
    return getTransactionFixedName(this, item);
  }

  iconOptions = {
    0: ['check2-circle', 'success'], // TransactionStatus.Successful: [icon, variant]
    1: ['question-circle', 'info'], // TransactionStatus.Pending
    2: ['x-circle', 'danger'], // TransactionStatus.Cancelled
    3: ['exclamation-circle', 'warning'], // TransactionStatus.Error
  }
}
