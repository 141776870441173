import { Currency } from '@/serviceClients/userWebServices';
import * as axios from 'axios';

const url = `${process.env.VUE_APP_API_URL}/payout`;

export enum PayoutType {
    PayPal = 1,
    BankTransfer = 2,
}

export interface PayoutRequestModel {
    transactionId: string;
    payoutId: string;
    amount: number;
    type: PayoutType;
    details: string;
    comment: string;
    currency: string;
}

export const createPaypalPayout = (data: {
    amount: number,
    feeInCoins: number,
    currency: Currency,
    email: string
}) => {
    return axios.default.post<PayoutRequestModel>(`${url}/paypal`, data);
}

export const createBankTransferPayout = (data: {
    amount: number,
    feeInCoins: number,
    currency: Currency,
    address: string,
    iban: string,
    swift: string
}) => {
    return axios.default.post<PayoutRequestModel>(`${url}/bank-transfer`, data);
}

export const fetchLastPendingPayout = () => {
    return axios.default.get<PayoutRequestModel>(`${url}/last-pending`);
}

export const cancelPayoutRequest = (id: string) => {
    return axios.default.post<PayoutRequestModel>(`${url}/${id}/cancel`);
}
