
import { TransactionStatus, TransactionType } from "@/serviceClients/userWebServices";
import store from "@/store";
import { Component, Prop } from "vue-property-decorator";
import { currencies } from "../currencies";
import { createPaypalPayout } from "../payoutWebServices";
import PayoutFormBase, { PayoutForm } from "./PayoutFormBase";

export type PaypalForm = {
  email: string;
} & PayoutForm;

export const onPaypalPayout = async (value: PaypalForm, feeInCoins: number) => {
  const response = await createPaypalPayout({
      feeInCoins: feeInCoins,
      ...value,
    })

    store.commit.module.addTransaction({
      id: response.data.transactionId,
      amount: response.data.amount,
      comment: response.data.comment,
      transactionDateTime: new Date().toString(),
      status: TransactionStatus.Pending,
      type: TransactionType.PayOut,
    });

    return {
      amount: response.data.amount,
      type: response.data.type,
      payoutId: response.data.payoutId,
      details: response.data.details,
      currency: currencies.find((c) => c.code == response.data.currency),
    }
}

@Component
export default class PaypalPayoutForm extends PayoutFormBase {
  @Prop() value!: PaypalForm;

  mounted() {
    if (!this.value.email) {
      this.value.email = store.getters.module.user!.email;
    }

    this.updateStateValidity()
  }

  updateStateValidity() {
    this.$emit('input', {
      ...this.value,
      injectedFormIsValid: this.totalStateIsOk(),
    })
  }

  totalStateIsOk() {
    return !!this.emailState();
  }

  emailState() {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(this.value.email).toLowerCase());
  }
}
