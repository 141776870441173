const topUpPackages = [
    {
        coins: 10, priceInCurrency: [
            { currencyCode: 'USD', price: 10.99, oldPrice: 13.80 },
            { currencyCode: 'EUR', price: 9.99, oldPrice: 11.32 },
            { currencyCode: 'PLN', price: 45.99, oldPrice: 51.47 },
        ]
    },
    {
        coins: 50, priceInCurrency: [
            { currencyCode: 'USD', price: 54.99, oldPrice: 66 },
            { currencyCode: 'EUR', price: 49.99, oldPrice: 54.12 },
            { currencyCode: 'PLN', price: 229.99, oldPrice: 246.18 },
        ]
    },
    {
        coins: 100, priceInCurrency: [
            { currencyCode: 'USD', price: 109.99, oldPrice: 129 },
            { currencyCode: 'EUR', price: 99.99, oldPrice: 105.78 },
            { currencyCode: 'PLN', price: 459.99, oldPrice: 481.17 },
        ]
    },
    {
        coins: 500, priceInCurrency: [
            { currencyCode: 'USD', price: 549.99, oldPrice: 635 },
            { currencyCode: 'EUR', price: 499.99, oldPrice: 520.7 },
            { currencyCode: 'PLN', price: 2299.99, oldPrice: 2368.55 },
        ]
    },
    {
        coins: 1000, priceInCurrency: [
            { currencyCode: 'USD', price: 1099.99, oldPrice: 1250 },
            { currencyCode: 'EUR', price: 999.99, oldPrice: 1025 },
            { currencyCode: 'PLN', price: 4599.99, oldPrice: 4662.5 },
        ]
    },
];

export default topUpPackages