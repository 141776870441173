
import { Component, Prop, Vue } from "vue-property-decorator";
import facebookLogin from "../serviceClients/facebookLoginWebServices";
import { AxiosResponse } from "axios";
import { LoggedUserInformation } from "../login/loginModel";
import store from "../store/index";
import { getFromStorage } from "../helpers/storageHelper";
import { pushError } from "@/core";

@Component({})
export default class Facebook extends Vue {
  isLoading = false;

  get token() {
    return this.getQueryVariable("access_token");
  }

  mounted() {
    if (!this.token) {
      this.$router.push("/login")
          .catch(() => { /* noop */ });
      return;
    }
    this.isLoading = true;
    facebookLogin(this.token, getFromStorage("invitationId"))
      .then((response: AxiosResponse<LoggedUserInformation>) => {
        store.commit.module.setUser(response.data);
        store.dispatch.module.saveUserToStorage()
          .catch(() => { /* noop */ });
        this.$toasted.success(`Welcome, ${response.data.firstName}!`, {
          duration: 2000,
        });
        let urlToRedirectTo = "/panel"
        if (store.getters.module.urlToRedirectAfterLogon) {
          urlToRedirectTo = store.getters.module.urlToRedirectAfterLogon;
        }
        this.$router.push(urlToRedirectTo)
            .catch(() => { /* noop */ });
      })
      .finally(() => {
        this.isLoading = false;
      })
      .catch((error) => {
        const errorMessage = error.response?.data?.error || error;
        const opts = { title: this.$t("An error occurred while connecting to Facebook") };
        if (errorMessage === "InvalidCredentials") {
          pushError(this.$t("Provided email or password are invalid"), opts);
        }
        else {
          pushError(errorMessage, opts);
        }
      });
  }

  private getQueryVariable(variable: string) {
    var query = this.$route.fullPath;
    var vars = query.split("#")[1].split("&");
    for (var i = 0; i < vars.length; i++) {
      var pair = vars[i].split("=");
      if (decodeURIComponent(pair[0]) == variable) {
        return decodeURIComponent(pair[1]);
      }
    }
  }
}
