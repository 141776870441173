import { AdditionalPaymentRequestStatus } from "@/messaging/messagingWebServices";
import { Transaction, TransactionType } from "@/serviceClients/userWebServices"
import store from "@/store";

export const getTransactionFixedName = (vue: Vue, item: Transaction) => {
    if (
        item.type === TransactionType.Charge ||
        item.type === TransactionType.Payment
      ) {
        if (item.interlocutor) {
          return vue.$t("Conversation with {0}", [
            `${item.interlocutor.firstName} ${item.interlocutor.lastName}`,
          ]);
        } else if (item.videoCall) {
          const interlocutor =
            store.getters.module.user!.id === item.videoCall.fromUser.id
              ? item.videoCall.toUser
              : item.videoCall.fromUser;
          return vue.$t("Video call with {0}", [
            `${interlocutor.firstName} ${interlocutor.lastName}`,
          ]);
        }
      }
  
      if (item.additionalPaymentRequest) {
        if(item.type === TransactionType.Payment) {
          return vue.$t("Additional payment from {0}", [
            `${item.additionalPaymentRequest.toUser?.firstName} ${item.additionalPaymentRequest.toUser?.lastName}` ]).toString();
        } else if(item.type === TransactionType.Charge) {
          let result = vue.$t("Additional payment to {0}", [
            `${item.additionalPaymentRequest.fromUser?.firstName} ${item.additionalPaymentRequest.fromUser?.lastName}` ]).toString();
          if(item.additionalPaymentRequest.status === AdditionalPaymentRequestStatus.Approved) {
            result += ` (${vue.$t("Pending")})`;
          }
          return result;
        } else {
          return vue.$t("Invalid transaction. Please contact administrator.");
        }
      }
  
      if (item.type === TransactionType.PayOut) {
        return vue.$t("Payout");
      }
  
      if (item.type === TransactionType.OnlineTopUp) {
        return vue.$t("Topup");
      }
  
      if (item.type === TransactionType.OfflineTopUp) {
        return vue.$t("Offline topup");
      }
  
      return TransactionType[item.type];
}