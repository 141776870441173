import { TransactionStatus } from "@/serviceClients/userWebServices";

export const transactionStatusTranslate = (vue: Vue, status: TransactionStatus) => {
    
    switch(status) {
        case TransactionStatus.Successful: return vue.$t("Successful");
        case TransactionStatus.Pending: return vue.$t("Pending");
        case TransactionStatus.Cancelled: return vue.$t("Cancelled");
        case TransactionStatus.Error: return vue.$t("Error");
      }
}